/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Nunito', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  color: #ffffff;
}

h1 {
  color: #ffffff;
  font-size: 45px;
}


.App {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(120deg, rgba(23, 190, 187, 1), rgba(240, 166, 202, 1));
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.container {
  margin: 25px;
  display: flex;
  max-width: 1100px;
  height: 70vh;
  width: 70%;
  border-radius: 5px;
  box-shadow: 0 15px 15px -5px rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.05);
}

.sidebar {
  width: 100%;
  max-width: 200px;
  visibility: visible;
  background: rgba(255, 255, 255, 0.1);
  border-right: 1px solid #ffffff
}

.members {
  border-bottom: 1px solid #ffffff;
  border-left: 5px solid;
  list-style-type: none;
  padding: 10px;
}

.messages {
  flex-grow: 1;
  overflow: auto;
  padding-right: 25px;
}

.personal_message {
  float: right;
  margin-top: 25px;
  padding: 5px;
  list-style-type: none;
  width: 80%;
  border-right: 3px solid;
  background-color: rgba(255, 255, 255, 0.2);
}

.members_message {
  padding: 10px;
  width: 80%;
  float: left;
  margin-top: 25px;
  border-left: 3px solid;
  background-color: rgba(255, 255, 255, 0.2);
}

.chatbox {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
}

.sender {
  display: flex;
  align-items: flex-start;
}

.senders_text {
  padding-top: 5px;
}

.senders_text_time p {
  display: flex;
  align-items: flex-end;
  float: right;
  font-size: 12px;
}

.input_field_box {
  margin-top: 20px;
  flex-grow: 0;
  display: flex;
}

.input_field {
  flex-grow: 1;
  border: none;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 10px;
  border-top-left-radius: 3px;
}

.send-message-button {
  background-color: rgba(255, 255, 255, 0.4);
  border: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  color: #ffffff;
}